<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  validations: {
    payee: {
      name: { required },
      bank: { required },
      agency: { required },
      agency_digit: {},
      account: { required },
      account_digit: { required },
      account_type: { required },
    }
  },
  data() {
    return {
      bank: {
        code: '',
        label: '',
      },
      banks: [],

      payee: {
        loading: false,

        name: "",
        person_type: 1,
        person_number: "",
        bank: "",
        agency: "",
        agency_digit: "",
        account: "",
        account_digit: "",
        account_type: 1,
      }
    };
  },
  methods: {
    getBancos() {
      api.get("favorecidos/bancos").then((response) => {
        if (response.data.status == "success") {
          this.banks = response.data.list;
        }
      })
    },
    getFavorecido() {
      api
        .get("favorecidos/" + this.$route.params.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.payee.name = response.data.favorecido.name;
            this.payee.person_type = response.data.favorecido.person_type;
            this.payee.person_number = response.data.favorecido.person_number;
            this.payee.bank = response.data.favorecido.bank;
            this.payee.agency = response.data.favorecido.agency;
            this.payee.agency_digit = response.data.favorecido.agency_digit;
            this.payee.account = response.data.favorecido.account;
            this.payee.account_digit = response.data.favorecido.account_digit;
            this.payee.account_type = response.data.favorecido.account_type;

            this.bank.code = response.data.favorecido.bank;
            this.bank.label = response.data.favorecido.bank_name;
          } else {
            this.$router.push("/favorecidos");
          }
        })
        .catch((error) => {
          if (error) {
            this.$router.push("/favorecidos");
          }
        });
    },
    editarFavorecido() {
      console.log(this.payee.bank);
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.payee.loading = true;

        api
          .post("favorecidos/" + this.$route.params.id, {
            name: this.payee.name,
            bank: this.payee.bank,
            agency: this.payee.agency,
            agency_digit: this.payee.agency_digit,
            account: this.payee.account,
            account_digit: this.payee.account_digit,
            account_type: this.payee.account_type,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$v.$reset();

              this.$router.push('/favorecidos');
              this.$toast.success('O favorecido foi editado com sucesso.');
            } else {
              this.$toast.error(response.data.message);
            }

            this.payee.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getBancos();
    this.getFavorecido();
  },
  watch: {
    person_type: function () {
      this.person_number = "";
    },
    'bank': function (v) {
      this.payee.bank = v.code

      console.log(this.payee);
    },
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/favorecidos">Favorecidos</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Editar favorecido</li>
    </ol>

    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Editar favorecido</h3>
        <p></p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-5">
            <b-form @submit.prevent="editarFavorecido">
              <b-form-group id="name" label="Nome Completo" label-for="name">
                <b-form-input id="name" v-model="payee.name" type="text" :class="{ 'is-invalid': $v.payee.name.$error }"></b-form-input>
                <div v-if="$v.payee.name.$error" class="invalid-feedback">
                  <span v-if="!$v.payee.name.required">O nome completo é obrigatório.</span>
                </div>
              </b-form-group>
              <b-form-group id="person_type" label="Tipo de Pessoa" label-for="person_type">
                <b-form-input v-if="payee.person_type == 1" id="person_type" class="bg-soft-primary" type="text" value="Pessoa Física" disabled></b-form-input>
                <b-form-input v-else id="person_type" class="bg-soft-primary" type="text" value="Pessoa Jurídica" disabled></b-form-input>
              </b-form-group>
              <template v-if="payee.person_type == 1">
                <b-form-group id="person_number" label="CPF" label-for="person_number">
                  <b-form-input id="person_number" class="bg-soft-primary" v-model="payee.person_number" type="text" disabled></b-form-input>
                </b-form-group>
              </template>
              <template v-else>
                <b-form-group id="person_number" label="CNPJ" label-for="person_number">
                  <b-form-input id="person_number" class="bg-soft-primary" v-model="payee.person_number" type="text" disabled></b-form-input>
                </b-form-group>
              </template>
              <b-form-group id="bank" label="Banco" label-for="bank">
                <v-select v-model="bank" :options="banks"></v-select>
                <div v-if="$v.payee.bank.$error" class="invalid-feedback">
                  <span v-if="!$v.payee.bank.required">O banco é obrigatório.</span>
                </div>
              </b-form-group>
              <div class="row">
                <div class="col-8">
                  <b-form-group id="bank" label="Agência" label-for="bank">
                    <b-form-input id="bank" v-model="payee.agency" type="text" :class="{ 'is-invalid': $v.payee.agency.$error }"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group id="bank" label="Digito" label-for="bank">
                    <b-form-input id="bank" v-model="payee.agency_digit" type="text" :class="{ 'is-invalid': $v.payee.agency_digit.$error }"></b-form-input>
                  </b-form-group>
                </div>
                <div v-if="$v.payee.agency.$error" class="col-12 invalid-feedback">
                  <span v-if="!$v.payee.agency.required">A agência é obrigatório.</span>
                </div>
                <div v-if="$v.payee.agency_digit.$error" class="col-12 invalid-feedback">
                  <span v-if="!$v.payee.agency_digit.required">O digito é obrigatório.</span>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <b-form-group id="bank" label="Conta" label-for="bank">
                    <b-form-input id="bank" v-model="payee.account" type="text" :class="{ 'is-invalid': $v.payee.account.$error }"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group id="bank" label="Digito" label-for="bank">
                    <b-form-input id="bank" v-model="payee.account_digit" type="text" :class="{ 'is-invalid': $v.payee.account_digit.$error }"></b-form-input>
                  </b-form-group>
                </div>
                <div v-if="$v.payee.account.$error" class="col-12 invalid-feedback">
                  <span v-if="!$v.payee.account.required">A conta é obrigatório.</span>
                </div>
                <div v-if="$v.payee.account_digit.$error" class="col-12 invalid-feedback">
                  <span v-if="!$v.payee.account_digit.required">O digito é obrigatório.</span>
                </div>
              </div>
              <b-form-group id="account_type" label="Tipo da Conta" label-for="account_type">
                <select v-model="payee.account_type" class="form-control" :class="{ 'is-invalid': $v.payee.account_type.$error }">
                  <option value="1">Conta Corrente</option>
                  <option value="2">Conta Poupança</option>
                  <option value="3">Conta Pagamento</option>
                  <option value="4">Conta Salário</option>
                </select>
                <div v-if="$v.payee.account_type.$error" class="invalid-feedback">
                  <span v-if="!$v.payee.account_type.required">O tipo de conta é obrigatório.</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button :disabled="this.payee.loading == true || this.$v.$invalid" type="submit" variant="default">
                  Salvar
                  <b-spinner v-if="payee.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>